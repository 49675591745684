import { AxiosResponse, CancelToken } from 'axios';
import { emperor } from '~/services';
import {
  IAssignmentTag,
  IAssignmentTagProperty,
  IBuildingWithClient,
  IOwnerGroup,
  IOwnerGroupAsset,
  IOwnerGroupGoogleRevew
} from '~/types/assets';

export const getOwnerGroupAssetsRequest = async (
  payloadData: { ownerGroupId?: string; propertyMngGroupId?: string },
  cancelToken?: CancelToken
): Promise<{
  data: IOwnerGroupAsset[];
}> => {
  return await emperor.get(`/assets/overview`, {
    params: payloadData,
    cancelToken: cancelToken
  });
};

export const getOwnerGroupGoogleReviewRequest = async (
  payloadData: { ownerGroupId?: string; propertyMngGroupId?: string },
  cancelToken?: CancelToken
): Promise<{
  data: IOwnerGroupGoogleRevew[];
}> => {
  return await emperor.get(`/assets/google-reviews`, {
    params: payloadData,
    cancelToken: cancelToken
  });
};

export const getAssignmentTagsRequest = async (
  cancelToken?: CancelToken
): Promise<{ data: IAssignmentTag[] }> => {
  return await emperor.get(`/assets/assignment-tags`, {
    params: {
      cancelToken: cancelToken
    }
  });
};

export const patchAssignmentTagAttributes = async (
  attributes: Partial<IAssignmentTag>
): Promise<{ data: Partial<IAssignmentTag> }> => {
  return await emperor.patch(`/assets/assignment-tags-attributes`, attributes);
};

export const getTagPropertiesRequest = async (
  cancelToken?: CancelToken
): Promise<{ data: IAssignmentTagProperty[] }> => {
  return await emperor.get(`/assets/assignment-tag-properties`, {
    params: {
      cancelToken: cancelToken
    }
  });
};

export const postAssignmentTagBulkCSV = async (
  formData: FormData
): Promise<AxiosResponse<any>> => {
  return await emperor.post(`/assets/bulk-tag-assignment`, formData);
};

export const postAssignmentTagRequest = async (
  tagName: string
): Promise<void> => {
  return await emperor.post('/assets/create-new-tag', { tagName });
};

export const postChangeAssetAssignment = async (
  propertyId: string,
  newTagUserUuid?: string,
  oldTagUserUuid?: string
): Promise<void> => {
  return await emperor.patch('/assets/change-assignment-tag', {
    propertyId,
    newTagUserUuid: newTagUserUuid,
    oldTagUserUuid
  });
};

export const getAllClientGroupRequest = async (
  cancelToken?: CancelToken
): Promise<{ data: IOwnerGroup[] }> => {
  return await emperor.get(`/assets/client-groups`, {
    params: {
      cancelToken: cancelToken
    }
  });
};

export const getAllPropertiesWithClientRequest = async (
  cancelToken?: CancelToken
): Promise<{ data: IBuildingWithClient[] }> => {
  return await emperor.get(`/assets/all-properties-with-client`, {
    params: {
      cancelToken: cancelToken
    }
  });
};

export const postCreateOwnerGroup = async (
  ownerName: string,
  isAlsoPropertyMngGroup?: boolean,
  cancelToken?: CancelToken
): Promise<void> => {
  return await emperor.post(`/assets/new-owner-group`, {
    ownerName,
    isAlsoPropertyMngGroup,
    cancelToken: cancelToken
  });
};

export const postCreatePropertyMngGroup = async (
  parentClientId: string,
  propertyMngGroupName: string,
  cancelToken?: CancelToken
): Promise<void> => {
  return await emperor.post(`/assets/new-property-mng-group`, {
    parentClientId,
    propertyMngGroupName,
    cancelToken: cancelToken
  });
};

export const postAddPropertyToOwners = async (
  parentClientId: string,
  clientId: string,
  buildingId: string,
  cancelToken?: CancelToken
): Promise<void> => {
  return await emperor.post(`/assets/assign-property-to-owners`, {
    clientId,
    parentClientId,
    buildingId,
    cancelToken: cancelToken
  });
};

export const postAssignPmToOwner = async (
  parentClientId: string,
  clientId: string,
  cancelToken?: CancelToken
): Promise<void> => {
  return await emperor.post(`/assets/assign-pm-mng-group-to-owner`, {
    clientId,
    parentClientId,
    cancelToken: cancelToken
  });
};

export const removePropertyAssignment = async (
  buildingId: string
): Promise<void> => {
  return await emperor.delete(`/assets/remove-property-assignment`, {
    params: { buildingId }
  });
};