import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getGoogleReviewSummary, markAllReviewsAsNonCobu } from "~/api/review"
import CobuConstants from "~/helpers/cobuConstants"
import { GoogleReviewSummaryType } from "~/types/reviewReceived"


export default () => {

    const [reviewSummary, setReviewSummary] = useState<GoogleReviewSummaryType[]>([])
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    })

    useEffect(() => { getReviewSummary() }, [])

    const getReviewSummary = async () => {
        setLoadingConfig({ flag: true, comment: CobuConstants.googleReview.fetchGoogleSummary })
        try {
            const googleReviewSummaryResp = await getGoogleReviewSummary()
            setReviewSummary(googleReviewSummaryResp.data)
            setLoadingConfig({ flag: false })
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }



    const markAllAsNonCobu = async (buildingId: string) => {
        setLoadingConfig({ flag: true, comment: 'Marking all reviews as non-cobu...' });
        try {
            await markAllReviewsAsNonCobu(buildingId);
            await getReviewSummary();
            toast('Successfully marked all reviews as non-cobu');
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };

    return { reviewSummary, loadingConfig, markAllAsNonCobu }
}