import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useQuestionnaireResult from './hooks';
import { IntroParagraphMarkups, QuestionSubType } from '../constants';
import CobuTable from '~/components/CobuTable/CobuTable';
import { npsColumns, QuestionTypeColorCodes } from './constants';
import Spinner from '~/components/Spinner/Spinner';
import Spacer from '~/components/Spacer/Spacer';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { mergedFieldString } from '~/utils/stringUtils';
import classes from './QuestionnaireResult.module.scss';
import { uniqueId } from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ReferenceLine,
  Text
} from 'recharts';

export const QuestionnaireResult = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const questionnaireId = queryParams.get('questionnaireId') as string;

  const {
    barChatData,
    otherCCI,
    npsWithScores,
    questionWithAnswers,
    isLoading,
    buildingName
  } = useQuestionnaireResult(questionnaireId, buildingId);

  const CustomLabel = ({ viewBox }: { viewBox: any }) => {
    const { x, y, width } = viewBox;
    return (
      <Text
        x={width + x / 2}
        y={y - 5}
        fill='green'
        textAnchor='end'
        fontSize={20}
        fontWeight={500}
      >
        {`Cobu Average = 42.5`}
      </Text>
    );
  };

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <Spacer height={20} />
      <div>
        {isLoading && <Spinner />}
        {barChatData && (
          <div>
            <div className='heading semi-bold'>CCI Details</div>
            <Spacer height={20} />
            <ResponsiveContainer width='100%' height={400}>
              <BarChart
                width={500}
                height={300}
                data={Object.values(barChatData)}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='label' />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar
                  key={uniqueId()}
                  dataKey={QuestionSubType.MEMBERSHIP}
                  stackId={'stack'}
                  fill={QuestionTypeColorCodes[QuestionSubType.MEMBERSHIP]}
                >
                  <LabelList
                    dataKey={QuestionSubType.MEMBERSHIP}
                    position='center'
                    fill='black'
                  />
                </Bar>
                <Bar
                  key={uniqueId()}
                  dataKey={QuestionSubType.CONNECTION}
                  stackId={'stack'}
                  fill={QuestionTypeColorCodes[QuestionSubType.CONNECTION]}
                >
                  <LabelList
                    dataKey={QuestionSubType.CONNECTION}
                    position='center'
                    fill='black'
                  />
                </Bar>
                <Bar
                  key={uniqueId()}
                  dataKey={QuestionSubType.INFLUENCE}
                  stackId={'stack'}
                  fill={QuestionTypeColorCodes[QuestionSubType.INFLUENCE]}
                >
                  <LabelList
                    dataKey={QuestionSubType.INFLUENCE}
                    position='center'
                    fill='black'
                  />
                </Bar>
                <Bar
                  key={uniqueId()}
                  dataKey={QuestionSubType.NEEDS}
                  stackId={'stack'}
                  fill={QuestionTypeColorCodes[QuestionSubType.NEEDS]}
                >
                  <LabelList
                    dataKey={QuestionSubType.NEEDS}
                    position='center'
                    fill='black'
                  />
                </Bar>
                <ReferenceLine
                  y={42.5}
                  label={CustomLabel}
                  stroke='black'
                  strokeDasharray='4 4'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        {Object.keys(otherCCI).length > 0 && (
          <div>
            <div className='heading semi-bold'>Other CCI Details</div>
            <Spacer height={20} />
            <div className={classes.otherCCIContainer}>
              {Object.entries(otherCCI).map(([subType, totalData]) => {
                //@ts-ignore
                const { overallTotal, ...subTypeData } = totalData;

                return (
                  <div key={subType} className={classes.otherCCIOption}>
                    <div className='heading4'>{subType}</div>
                    <Spacer height={10} />
                    <div className={classes.optionGrid}>
                      {Object.entries(subTypeData).map(
                        ([optionLable, optionData]) => {
                          //@ts-ignore
                          const { total } = optionData;

                          return (
                            <div
                              className={`${classes.rowContainer} sub-heading semi-bold`}
                              key={optionLable}
                            >
                              <div>{optionLable}</div>
                              <div>{`${Math.round(
                                (total / overallTotal) * 100
                              )} %`}</div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <Spacer height={40} />
          </div>
        )}
        {Object.keys(npsWithScores).length > 0 && (
          <div>
            <div className='heading semi-bold'>NPS Questions</div>
            <Spacer height={20} />
            {npsWithScores.map((npsWithScore) => {
              const formattedQuestion = mergedFieldString(
                npsWithScore.question,
                {
                  [IntroParagraphMarkups.buildingName]: buildingName
                },
                IntroParagraphMarkups
              );

              return (
                <div key={uniqueId()}>
                  <div className='sub-heading semi-bold'>
                    {formattedQuestion}
                  </div>
                  <Spacer height={10} />
                  <CobuTable columns={npsColumns} data={[npsWithScore]} />
                  <Spacer height={40} />
                </div>
              );
            })}
          </div>
        )}
        {questionWithAnswers.length > 0 && (
          <div>
            <div className='heading semi-bold'>Question With Answers</div>
            <Spacer height={10} />
            {questionWithAnswers.map(({ question, answers, id }) => {
              if (!answers.length) {
                return null;
              }

              const formattedQuestion = mergedFieldString(
                question,
                {
                  [IntroParagraphMarkups.buildingName]: buildingName
                },
                IntroParagraphMarkups
              );

              return (
                <div key={id}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      <div className='sub-heading semi-bold'>{`${formattedQuestion}`}</div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.answerContainer}>
                      {answers.map(({ answer, id }, i) => (
                        <div className='paragraph' key={id}>
                          {`${i + 1}. ${answer}`}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
