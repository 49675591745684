import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import styles from './GoogleReview.module.css'
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spacer from "~/components/Spacer/Spacer";
import Spinner from "~/components/Spinner/Spinner";
import CobuConstants from "~/helpers/cobuConstants";
import useGoogleReviewSummary from "~/hooks/googlereviews/useGoogleReviewSummary";
import { GoogleReviewSummaryType } from "~/types/reviewReceived";
import WrappableText from "~/components/CollapsibleText/WrappableText";
import GenericModal from "~/components/GenericModal/GenericModal";

const GoogleReviewSummary = () => {

    const { reviewSummary, loadingConfig, markAllAsNonCobu } = useGoogleReviewSummary()
    const history = useHistory()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [selectedBuildingId, setSelectedBuildingId] = useState<string | null>(null)

    /* UI Logic */

    const googleReviewSummaryTableConfig = [
        {
            Header: 'BUILDING',
            accessor: 'name',
            Cell: (cell: CellProps<GoogleReviewSummaryType>) => {

                return <WrappableText text={cell.row.original.name} />
            }
        },
        {
            Header: 'REVIEWS',
            accessor: 'count'
        },
        {
            id: 'attribute',
            Cell: (cell: CellProps<GoogleReviewSummaryType>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => {
                        
                        history.push(`/building/google-review-summary/${cell.row.original.uuid}`)
                    }}>
                    {CobuConstants.googleReview.assignReview}
                </div>
            )
        },
        {
            id: 'markAllNonCobu',
            Cell: (cell: CellProps<GoogleReviewSummaryType>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => {
                        setSelectedBuildingId(cell.row.original.uuid);
                        setShowConfirmModal(true);
                    }}>
                    MARK ALL AS NON COBU
                </div>
            )
        }
    ]


    const getCobuTableInstance = (data: GoogleReviewSummaryType[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={googleReviewSummaryTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
            />
        );
    };

    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>


    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.googleReview.googleSummaryReviewTitle}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(reviewSummary, CobuConstants.googleReview.noReviewsSummary)}
            <GenericModal
                showModal={showConfirmModal}
                title="Confirm Action"
                content="Are you sure you want to mark all reviews as non-cobu? This action cannot be undone."
                actionText="Confirm"
                actionFunction={() => {
                    if (selectedBuildingId) {
                        markAllAsNonCobu(selectedBuildingId);
                        setShowConfirmModal(false);
                        setSelectedBuildingId(null);
                    }
                }}
                onChange={(show) => {
                    setShowConfirmModal(show);
                    if (!show) setSelectedBuildingId(null);
                }}
            />
        </div>
    )
}

export default GoogleReviewSummary;