import { emperor } from '~/services';
import { limitPerPage } from '~/helpers/constants';
import { AttributeReviewType, GoogleInvitationSettings, SearchReviewResultsType } from '~/types/reviewReceived';
import { Building } from '~/types/building';

export const createReviewReceived = async (data: {
  residentReviewInvitationId: string;
  reviewName: string;
  reviewDate: string;
  reviewText: string;
  reviewNotes: string;
  reviewRating: number;
  reviewLink: string;
  buildingId: string;
}) => {
  return await emperor.post(`/reviewreceived`, data);
};

export const fetchReviewsReceivedRequest = async (
  buildingId: string,
  page: number
) => {
  return await emperor.get(`/reviewreceived`, {
    params: { id: buildingId, limit: 50, page }
  });
};

export const fetchAllReviewsRequest = async (
  buildingId: string
) => {
  return await emperor.get(`/allreviews`, {
    params: { id: buildingId }
  });
};

export const removeAssignment = async (meta: SearchReviewResultsType) => {
  return await emperor.post(`/removereviewassignment/${meta.building}/${meta.uuid}`, )
}

export const editReviewReceived = async (
  reviewId: string,
  data: {
    residentReviewInvitationId: string;
    reviewName: string;
    reviewDate: string;
    reviewText: string;
    reviewNotes: string;
    reviewRating: number;
    reviewLink: string;
    buildingId: string;
  }
) => {
  return await emperor.patch(`/reviewreceived/${reviewId}`, data);
};


export const getGoogleReviewSummary = async () => {
  return await emperor.get('/googlereviews/summary')
}

export const getGoogleReviewsForBuilding = async (buildingId: string) => {
  return await emperor.get(`/googlereviews/${buildingId}`)
}

export const getGoogleReviewDetails = async (reviewId: string) => {
  return await emperor.get(`/googlereview/${reviewId}`)
}

export const attributeGoogleReview = async (meta: AttributeReviewType) => {
  return await emperor.post(`/googlereviews/${meta.uuid}`, meta)
}

export const getGoogleReviewsSettingsForBuilding = async (buildingId: string) => {
  return await emperor.get(`/googlereviews/settings/${buildingId}`)
}


export const updateGoogleReviewsSettingsForBuilding = async (building: string, settings: GoogleInvitationSettings) => {
  return await emperor.patch(`/googlereviews/settings/${building}`, { dataToUpdate: settings })
}


export const getReviewAlgorithmResults = async (building: Partial<Building>,settings:GoogleInvitationSettings) => {
  return await emperor.post(`/building/residentscore-summary`,{building,settings})
}

export const markAllReviewsAsNonCobu = async (buildingId: string) => {
  return await emperor.post(`/googlereviews/${buildingId}/mark-all-non-cobu`)
}